import React, { Fragment, useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import classNames from "classnames";
import SvgLogo from "../lib/SvgLogo";
import Button from "@/components/actions/Button";
import Modal from "@/components/lib/Modal";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { Listbox } from "@headlessui/react";

import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";

import * as Text from "@/components/text";
import useWindowSize from "@/hooks/useWindowSize";
import {
  ArrowNarrowRightIcon,
  ChevronDownIcon,
  PlayIcon,
  DownloadIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import breakpoints from "@/constants/breakpoinst";

function calculateTimeLeft(countdown) {
  const target = new Date(countdown).getTime();
  const difference = target - new Date().getTime();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

export default function TourBanner({
  className,
  title = "",
  description = "",
  image = "",
  logo = "",
  countdown = "",
  ...rest
}) {
  const [timeLeft, setTimeLeft] = React.useState(0);

  const css = classNames(
    className,
    "md:grid grid-cols-2 gap-10 bg-[#0072AE] text-white"
  );

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(countdown));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const stats = Object.keys(timeLeft).map((s, i) => {
    return {
      number: timeLeft[s],
      description: s,
    };
  });
  const windowSize = useWindowSize();

  return (
    <div {...rest} className={css}>
      <div className="space-y-3 md:space-y-5 p-4 md:p-6 lg:p-12 relative overflow-hidden h-full">
        <div className="w-[150px] md:w-[200px] z-10">
          <Image
            alt="logotipo de ambulante"
            width={200}
            height={80}
            src={logo}
            className=""
          ></Image>
        </div>

        <div className="space-y-2 pb-4">
          <Text.Headline as="h3" size={windowSize.width < 720 ? "sm" : "xl"}>
            {title}
          </Text.Headline>
          <div className="max-w-[500px]">
            <Text.Body size={windowSize.width < 720 ? "lg" : "xl"}>
              {description}
            </Text.Body>
          </div>
        </div>

        <div className="flex w-full">
          <ClockIcon className="mr-2 w-7" />
          <Text.Title size={windowSize.width < 720 ? "lg" : "xl"} as="h3">
            Faltan:
          </Text.Title>
        </div>
        <div className="grid grid-cols-4 w-fit items-center justify-center">
          <Text.Headline size={windowSize.width < 720 ? "md" : "lg"} >
            {timeLeft?.days} :&nbsp;
          </Text.Headline>
          <Text.Headline size={windowSize.width < 720 ? "md" : "lg"} >
            {timeLeft?.hours} :
          </Text.Headline>
          <Text.Headline size={windowSize.width < 720 ? "md" : "lg"} >
            {timeLeft?.minutes} :
          </Text.Headline>
          <Text.Headline size={windowSize.width < 720 ? "md" : "lg"} >
            {timeLeft?.seconds}
          </Text.Headline>
          <Text.Body size={windowSize.width < 720 ? "sm" : "md"}>Días</Text.Body>
          <Text.Body size={windowSize.width < 720 ? "sm" : "md"}>Horas</Text.Body>
          <Text.Body size={windowSize.width < 720 ? "sm" : "md"}>Minutos</Text.Body>
          <Text.Body size={windowSize.width < 720 ? "sm" : "md"}>Segundos</Text.Body>
        </div>

        <div className="flex gap-3">
          <Link href="/iniciativa/gira/" passHref>
            <Button
              color="secondary-dark"
              size="lg"
              className="mt-5 w-full md:w-auto"
              trailingIcon={<ArrowNarrowRightIcon />}
              label="Conoce más"
            ></Button>
          </Link>
        </div>

      </div>
      <div className="z-0 hidden md:block w-full">
        <Image
          alt="tour banner image"
          style={{ width: 'auto' }}
          width={500}
          height={300}
          src={image}
        // className="w-full"
        ></Image>
      </div>

      <div
        style={{
          backgroundImage: `url('${image}')`,
        }}
        className="hidden md:block bg-cover bg-center bg-no-repeat"
      ></div>
    </div>
  );
}

export function NumberBox({ number = 0, description = "", className = "" }) {
  const css = classNames(
    className,
    "bg-white/8 aspect-square  flex items-center align-middle"
  );
  const windowSize = useWindowSize();
  return (
    <div className={css}>
      <div className="m-auto block text-center">
        <Text.Display size="sm" fontWeight="light">
          {number}
        </Text.Display>
        <br></br>

        <Text.Overline
          className="text-white/64"
          size={windowSize.width < 720 ? "sm" : "md"}
        >
          {description}
        </Text.Overline>
      </div>
    </div>
  );
}

export function MovieBanner({ image = "" }) {
  return (
    <div className="aspect-2/3 relative max-w-[500px] bg-black text-white md:max-w-[700px]">
      <img
        src="/next_assets/img/backgrounds/tour_top.png"
        className="absolute left-0 top-0 z-10 w-full object-cover"
      ></img>
      <div className="relative left-0 top-0 z-20 w-1/2 space-y-5 p-10">
        <div>
          <SvgLogo className="max-w-[250px]" />
          <Text.Label size="md">Gira de Documentales</Text.Label>
        </div>
        <Text.Body size="lg">
          Presentándose en la décimo-séptima edición de la Gira Ambulante, 2023.&nbsp;
        </Text.Body>
        <Button
          color="terciary-dark"
          label="Ir a la gira ambulante"
          className="w-3/4"
        ></Button>
      </div>
      <div className="absolute -right-5 bottom-10 z-30 aspect-square w-[250px] ">
        <img className="h-full w-full object-cover" src={image}></img>
      </div>
    </div>
  );
}

export function TourBannerHero({
  simple = false,
  title = "",
  description = "",
  videoUrl = "",
  className,
  fromDate = "",
  toDate = "",
  image = "",
  ...rest
}) {
  const imageRef = useRef(null);
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());
  const [openModal, toggleModal] = useState(false);
  const [selectedGuide, setSelectedGuide] = React.useState(null);
  const [showOptions, setShowOptions] = React.useState(false);
  const windowSize = useWindowSize();

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  useEffect(() => {
    if (imageRef.current) {
      if (image) {
        imageRef.current.style.backgroundImage = `url(${image})`;
      } else {
        imageRef.current.style.backgroundImage = `url('/next_assets/img/brand-assets/gira-lines-global.svg')`;
      }
    }
  }, []);

  const guideOptions = [
    {
      label: "Programa de mano de la Ciudad de México",
      body: "",
      href: "https://cdn.ambulante.org/PM_CDMX_2025_web_2_bb9337459b.pdf",
    },
    // {
    //   label: "Programa de mano de Chihuahua",
    //   body: "",
    //   href: "https://ambulante-pdf.s3.amazonaws.com/PM_CHIHUAHUA_2023_DIGITAL(1).pdf",
    // },
    // {
    //   label: "Programa de mano de Aguascalientes",
    //   body: "",
    //   href: "https://ambulante-pdf.s3.amazonaws.com/PM_AGUASCALIENTES_2023_c.pdf",
    // },
    // {
    //   label: "Programa de mano de Veracruz",
    //   body: "",
    //   href: "https://ambulante-aws-s3-media.s3.us-west-2.amazonaws.com/Programa_Veracruz_WEB_bf2702816f.pdf",
    // },
    // {
    //   label: "Programa de mano de Michoacán",
    //   body: "",
    //   href: "https://ambulante-aws-s3-media.s3.us-west-2.amazonaws.com/Programa_Michoacan_WEB_c0eafd5080.pdf",
    // },
    // {
    //   label: "Programa de mano de Querétaro",
    //   body: "",
    //   href: "https://ambulante-aws-s3-media.s3.us-west-2.amazonaws.com/PM_Queretaro_WEB_012d716f38.pdf",
    // },

  ];

  return (
    <>
      <div {...rest} className="relative bg-[#0072AE] text-white ">
        <div className="amb-container h-full grid-cols-2 bg-contain bg-fixed bg-right bg-no-repeat md:grid  md:bg-[#0072AE] ">
          <div
            className={classNames(
              !simple ? "py-2 lg:py-3" : "py-5 lg:py-10",
              "z-20 flex flex-col justify-center space-y-7 md:h-full "
            )}
          >
            <div className="">
              {simple && (
                <SvgLogo className="mb-2 max-w-[200px] md:max-w-[300px] " />
              )}

              <Text.Body
                size={
                  windowSize.width > breakpoints.lg
                    ? "xl"
                    : windowSize.width > breakpoints.md
                      ? "lg"
                      : "md"
                }
                className="text-white"
              >
                {title}
              </Text.Body>
            </div>

            {simple && (
              <motion.div
                initial={{ opacity: 0, height: "auto" }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  height: 0,
                }}
                className={"max-w-lg"}
              >
                <Text.Headline
                  className="mb-2"
                  as="h1"
                  size={windowSize.width < breakpoints.md ? "sm" : "lg"}
                >
                  {fromDate &&
                    toDate &&
                    `Del ${format(parseISO(fromDate), "dd LLLL", {
                      locale: es,
                    })} al ${format(parseISO(toDate), "dd LLLL", {
                      locale: es,
                    })}`}
                </Text.Headline>
                <Text.Label
                  size={windowSize.width < breakpoints.md ? "md" : "lg"}
                >
                  {description}
                </Text.Label>
                <div className="my-5 grid grid-cols-2 gap-5  md:flex">
                  <div className="relative">
                    <Listbox
                      as="div"
                      className="z-10"
                      value={selectedGuide}
                      onChange={setSelectedGuide}
                    >
                      <Listbox.Button as={Button} color='terciary-dark'
                        label='Descargar programas'
                        className="border border-neutral-800"
                        trailingIcon={<ChevronDownIcon width={32} height={32} />}
                        onClick={() => setShowOptions(!showOptions)}></Listbox.Button>
                      <Listbox.Options
                        as="div"
                        className="bg-white absolute  z-50 w-full"
                      >
                        {showOptions && guideOptions.map((option, i) => (
                          (<Link key={i} href={option.href} passHref target="_blank">

                            <div className="p-3 flex gap-2 items-center hover:bg-neutral-50 cursor-pointer active:bg-black active:text-white border border-t-0 border-gray-500 focus:ring-0">
                              <div className="group grow ">
                                <Text.Label
                                  size="sm"
                                  as="p"
                                  className="group-active:text-white text-neutral-1000"
                                >
                                  {option.label}
                                </Text.Label>
                                <Text.Body
                                  size="sm"
                                  className="text-neutral-700 group-active:text-white/60"
                                >
                                  {option.body}
                                </Text.Body>
                              </div>
                              <div className="text-neutral-800 group-active:text-white/60">
                                <DownloadIcon className="w-5 h-5" />
                              </div>
                            </div>

                          </Link>)
                        ))}
                      </Listbox.Options>
                    </Listbox>
                  </div>
                  {videoUrl && (
                    <Button
                      onClick={() => toggleModal(true)}
                      color="terciary-dark"
                      leadingIcon={<PlayIcon />}
                      label="Ver video"
                    ></Button>
                  )}

                  {/* <Link href="https://streaming.ambulante.org/" passHref>

                    <Button
                      color="terciary-dark"
                      className="w-full"
                      trailingIcon={<ArrowNarrowRightIcon />}
                      label="Ir a Gira digital"
                    ></Button>

                  </Link> */}
                </div>
              </motion.div>
            )}
          </div>

          <div
            ref={imageRef}
            className={classNames(
              simple ? "block" : "hidden",
              "hidden md:block bg-cover bg-center bg-no-repeat "
            )}
          >
            <div className="z-20 flex h-full  flex-col-reverse overflow-hidden py-5 lg:py-4">
              {/* <AnimatePresence>
              {simple && (
                <>
                  &nbsp;
                  <motion.div className="grid grid-cols-4 gap-2 mb-10 px-5 lg:px-20 ">
                    {stats.map((stat, index) => (
                      <NumberBox
                        className="bg-white/4 backdrop-blur-md"
                        key={index}
                        number={stat.number}
                        description={stat.description}
                      ></NumberBox>
                    ))}
                  </motion.div>
                  <Text.Label
                    size="xl"
                    className="text-white text-center mb-2"
                  >
                    Comienza en
                  </Text.Label>
                </>
              )}
            </AnimatePresence> */}
            </div>
          </div>
        </div>
        <Modal setIsOpen={toggleModal} isOpen={openModal}>
          <iframe
            width="100%"
            height="100%"
            className="aspect-video"
            src={videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal>
        {/* <div className="md:hidden pointer-events-none z-10 h-full w-full absolute top-0 left-0 overflow-hidden flex flex-row-reverse">
        <img
          className=" relative -right-32 object-cover z-10 right-0 "
          src="/next_assets/img/brand-assets/gira-lines-global.svg"
          alt=""
        />
      </div> */}
      </div>
    </>
  );
}
